import { formatTime } from './utils'

function MediaItem(props) {
  const { item, onClick, selected } = props
  console.log(item);
  const mediaType = item.MediaType

  let coverUrl
  let title
  let duration

  if (mediaType === 'image') {
    coverUrl = item.url
  } else if(mediaType === 'audio') {
    coverUrl = 'https://washoukd.oss-cn-shanghai.aliyuncs.com/i7QaJOA5Rk.png'
    duration = item.duration
  } else {
    coverUrl = item.url+'?x-oss-process=video/snapshot,t_1000,f_png,w_200,h_130'
    duration = item.duration
  }
  title = item.file_name
  
  const style = {
    background: `url(${coverUrl})`,
    height: '100%',
    backgroundSize: '100% 100%',
  }

  return (
    <div className={`item ${selected ? 'selected' : ''}`} style={{ width: '120px', marginBottom: '20px', height: '140px', border: '1px solid #6b6b6b' }} onClick={onClick}>
      <div className='cover' style={style} />
      {(
        <div style={{ display: duration ? 'block' : 'none' }} className='duration'>{formatTime(duration)}</div>
      )}
      <div className='title'>{title}</div>
    </div>
  )
}

export default MediaItem
