/*
 * @Author: zhoul09 zhoul09@mingyuanyun.com
 * @Date: 2023-11-02 17:24:00
 * @LastEditors: zhoul09 zhoul09@mingyuanyun.com
 * @LastEditTime: 2023-12-05 18:54:14
 * @FilePath: \fe\src\App.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Routes, Route } from 'react-router-dom'
import ProjectDetail from './ProjectDetail'
import ProjectList from './ProjectList'

function App () {
  return (
    <Routes>
      {/* <Route path='/' element={<ProjectList />} /> */}
      <Route path='/detail/:projectId' element={<ProjectDetail />} />
    </Routes>
  )
}

export default App
